import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const InternalLink = props => <StyledLink {...props} />;

const ExternalLink = ({ to, children, ...props }) => (
  <StyledOutboundLink href={to} target="_blank" rel="noopener" {...props}>
    {children}
  </StyledOutboundLink>
);

export { InternalLink, ExternalLink };

const noStyles = css`
  background: inherit;
  border-bottom: inherit;
  :hover,
  :focus,
  :active {
    background: inherit;
    border-bottom: inherit;
  }
`;

const darkBgStyles = css`
  color: white;
  border-radius: 8px;
  background: ${p => p.theme.colors.primary};
  padding: 4px 8px;
  :hover,
  :focus,
  :active {
    background: ${p => p.theme.colors.primary};
    border-bottom: 3px solid ${p => p.theme.colors.contrast};
  }
`;

export const linkStyles = css`
  text-decoration: none;
  color: ${props => props.theme.colors.black};
  border-bottom: 3px solid ${props => props.theme.colors.primaryLighter};
  transition: background 0.1s, border 0.1s;

  padding: 2px 2px;
  :focus {
    outline: 2px dashed ${props => props.theme.colors.black};
    outline-offset: 4px;
  }
  :hover,
  :focus {
    border-bottom: 3px solid ${props => props.theme.colors.primaryLight};
  }
  :active {
    border-bottom: 3px solid ${props => props.theme.colors.contrast};
  }

  ${p => !!p.noStyles && noStyles}

  ${p => !!p.onDarkBg && darkBgStyles}
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledOutboundLink = styled(OutboundLink)`
  ${linkStyles}
`;
