// export const colors = {
//   contrast: '#F0DB4F',
//   dark: '#002451',
// };

// I like this color a lot: #2e3192

const baseColors = {
  // Palette URL: http://paletton.com/#uid=23B0u0kBS++lz+frK+W+VZmZGxU */
  hex: {
    black: '#000000',
    black2: '#171D2D',
    white: '#ffffff',
    greyLight: '#EEEEEE',
    greyDark: '#CCCCCC',
    greyDarker: '#737373',
    primary: '#2e3192', // Main Primary color */
    primaryLight: '#484A9F',
    primaryLighter: '#d2d3e1',
    primaryDark: '#1D1F75',
    primaryDarker: '#0D0F5A',
    contrast: '#FF9A00', // Main Complement color */
  },
};

export const colors = baseColors.hex;

const theme = {
  colors: baseColors.hex,
};

export default theme;
