import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container } from './layoutComponents';
import { linkStyles } from './Links';
import { Cluster } from './system';
import { SignupFormContext } from '../contexts/signup-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import json2mq from 'json2mq';
import { breakpoint } from '../constants';
import { trackEvent } from '../lib';

const Footer = ({ className }) => {
  const { setIsOpen } = useContext(SignupFormContext);
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: breakpoint.sm,
    })
  );

  function onClickSignUp() {
    setIsOpen(o => !o);
    trackEvent({
      action: 'Click Email Sign Up Button',
      category: 'Registration',
      label: 'footer',
    });
  }

  return (
    <footer
      css={`
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
      `}
      className={className}
    >
      <Container>
        <div
          css={`
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          `}
        >
          <div
            css={`
              display: flex;
              align-items: center;
              > * + * {
                margin-left: 10px;
              }
            `}
          >
            {!isMobile && (
              <span>
                © {new Date().getFullYear()} {!isMobile && 'Beer Finder'}
              </span>
            )}
            {isMobile && (
              <EmailLink href="mailto:beerfinderca@gmail.com">
                <span role="img" aria-label="picture of hand waving">
                  👋
                </span>{' '}
                Say Hello
              </EmailLink>
            )}
          </div>
          <div>
            <Cluster
              lg
              css={`
                align-items: center;
              `}
            >
              {!isMobile && (
                <EmailLink href="mailto:team@beerfinder.ca?subject=Hello Beer Finder Team!">
                  <span role="img" aria-label="picture of hand waving">
                    👋
                  </span>{' '}
                  Say Hello
                </EmailLink>
              )}
              <button
                onClick={onClickSignUp}
                css={`
                  font-weight: bold;
                  ${linkStyles}
                  border: none;
                  border-bottom: 3px solid transparent;
                `}
                onDarkBg
              >
                Sign up to Beer Finder
              </button>
            </Cluster>
          </div>
        </div>
      </Container>
    </footer>
  );
};

const EmailLink = styled.a`
  /* padding: 10px 20px; */
  cursor: pointer;
  border-bottom: 3px solid transparent;
  color: ${props => props.theme.colors.white};
  :hover {
    color: ${props => props.theme.colors.white};
    border-bottom: 3px solid ${props => props.theme.colors.contrast};
  }
`;

const StyledFooter = styled(Footer)`
  color: ${props => props.theme.colors.white};
  background: #000;
  padding: 6px 0;
`;

export default StyledFooter;
